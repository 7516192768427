/* styles.css */

html {
  overflow: auto;
  margin: 0;
  padding: 0;
}

body {
  overflow: auto;
  margin: 0;
  padding: 0;
  background-color: #d9d7d3;
}

h1 {
  margin: 0;
  padding: 0;
}

#root {
  margin: 0;
  padding: 0;
  background-color: #d9d7d3; 
}

.main-container {
  background-color: #d9d7d3;
  min-height: 100vh;
}

/* Chatbot.js styles */

.chatbot-container {
  width: 100%;
  margin-top: 0px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.chat-log {
  height: 300px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  background-color: #fff;
}

.message {
  margin-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.message strong {
  margin-right: 8px;
  color: #333;
}

.message-text {
  margin-left: 8px;
  color: #555;
}

.loading-message {
  text-align: center;
  font-style: italic;
  margin-top: 10px;
  color: #777;
}

.input-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

/* QABot.js styles */

.qabot-container {
  width: 100%;
  margin-top: 0px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.context-input-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.qa-log {
  height: 300px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  background-color: #fff;
}

.qa-entry {
  margin-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.qa-entry strong {
  margin-right: 8px;
  color: #333;
}
